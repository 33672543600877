import { captureRemixErrorBoundaryError } from "@sentry/remix";
import type { LinksFunction } from "@remix-run/cloudflare";
import type { LoaderFunctionArgs } from "@remix-run/server-runtime";
import { useEffect, useState } from "react";
import {
  isRouteErrorResponse,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLocation,
  useRouteError,
  useRouteLoaderData,
} from "@remix-run/react";
import { json } from "@remix-run/cloudflare";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { ClientOnly } from "remix-utils/client-only";
import { ExternalScripts } from "remix-utils/external-scripts";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// Assets
import logo from "./assets/logo/logo.png";

// Components
import LayoutComponent from "./components/layout/Layout";

// Styles
import "./styles/scss/main.scss";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import Credits from "./components/layout/Credits";

export const handle = {
  scripts: () => [
    {
      src: "https://code.jquery.com/jquery-3.7.1.slim.min.js",
      integrity: "sha256-kmHvs0B+OpCW5GVHUNjv9rOmY0IvSIRcf7zGUDTDQM8=",
      crossOrigin: "anonymous",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.min.js",
      crossOrigin: "anonymous",
    },
  ],
  breadcrumb: () => "Home",
};

export const links: LinksFunction = () => [
  { rel: "preconnect", href: "https://fonts.googleapis.com" },
  {
    rel: "preconnect",
    href: "https://fonts.gstatic.com",
    crossOrigin: "anonymous",
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap",
  },
];

export async function loader({ request, context }: LoaderFunctionArgs) {
  return json({
    ENV: {
      RECAPTCHA_SITE_KEY: context.cloudflare.env.RECAPTCHA_SITE_KEY,
    },
  });
}

const CountdownTimer = () => {
  // Target date: 11:00:00 on 17th of January 2025 CET
  const targetDate = new Date("2025-01-17T15:00:00").getTime();

  // Calculate the remaining time in seconds
  const calculateTimeRemaining = () => {
    const now = new Date().getTime(); // Current timestamp
    const difference = Math.max((targetDate - now) / 1000, 0); // Ensure non-negative value
    return Math.floor(difference);
  };

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(timerInterval);
  }, [targetDate]); // Dependency array includes targetDate

  // Convert seconds to hours, minutes, and seconds
  const hours = Math.floor(timeRemaining / 3600);
  const minutes = Math.floor((timeRemaining % 3600) / 60);
  const seconds = timeRemaining % 60;

  return (
    <div
      className="text-center mt-5 z-2 position-relative bg-secondary bg-opacity-50 p-2 rounded-2"
      style={{ width: "fit-content" }}
    >
      <p className="h3 text-tertiary">Beta test is live 🚀</p>
    </div>
  );
};

export function Layout({ children }: { children: React.ReactNode }) {
  const data = useRouteLoaderData("root");
  const location = useLocation();

  const { ENV } = data || {};

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 1000 * 60 * 60 * 24,
            gcTime: 1000 * 60 * 60 * 24,
            refetchOnWindowFocus: false,
            retry: false,
          },
        },
      })
  );

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", "dataLayer", "GTM-MQRDDK6K");`,
          }}
        ></script>
        <Meta />
        <Links />
      </head>
      <body className="bg-primary overflow-x-hidden">
        {/* <!-- Google Tag Manager (noscript) --> */}
        <noscript>
          <iframe
            title="Google Tag Manager"
            src="https://www.googletagmanager.com/ns.html?id=GTM-MQRDDK6K"
            height="0"
            width="0"
            style={{
              display: "none",
              visibility: "hidden",
            }}
          ></iframe>
        </noscript>
        {/* <!-- End Google Tag Manager (noscript) --> */}

        <QueryClientProvider client={queryClient}>
          {location.pathname.includes("checkout") ? null : <Header />}

          <div className="container">
            <div className="row">
              <div className="col-xxl-12 px-lg-4 px-md-4 px-sm-2 py-lg-5 py-md-5 py-sm-0 px-1 d-flex justify-content-center">
                <CountdownTimer />
              </div>
            </div>

            <div className="row">
              <div className="col-xxl-12 px-lg-4 px-md-4 px-sm-2 py-lg-5 py-md-5 py-sm-0 px-1">
                <GoogleReCaptchaProvider
                  reCaptchaKey={ENV?.RECAPTCHA_SITE_KEY}
                  language="nl"
                  scriptProps={{
                    async: true,
                    defer: true,
                  }}
                >
                  {children}
                </GoogleReCaptchaProvider>
              </div>
            </div>
          </div>

          <Footer />
          <div className="container bg-primary">
            <Credits />
          </div>
        </QueryClientProvider>

        <ScrollRestoration />
        <Scripts />
        <ExternalScripts />

        <ClientOnly>
          {() => (
            <script
              src="https://www.google.com/recaptcha/api.js?render=YOUR_SITE_KEY"
              async
              defer
            ></script>
          )}
        </ClientOnly>
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  console.log("ERROR", error);

  return (
    <div className="text-center bg-secondary p-4">
      <div className="logo mb-2">
        <img style={{ width: 200 }} src={logo} alt="404" />
      </div>
      <h1>Oops 😢</h1>
      <h2>
        {isRouteErrorResponse(error)
          ? `${error.status} ${error.statusText}`
          : error instanceof Error
          ? error.message
          : "Unknown Error"}
      </h2>

      <a href="/">
        <button className="btn btn-primary">Go back to home</button>
      </a>
    </div>
  );
};

export default function App() {
  return <Outlet />;
}
