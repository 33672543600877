import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Exposed from "./set/Exposed";
import Secure from "./set/Secure";
import Solution from "./set/Solution";
import Infobox from "./Infobox";

export default function Usps() {
  const [activeSlide, setActiveSlide] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const arrowLeft =
    "https://img.icons8.com/?size=18&id=85099&format=png&color=ffffff";
  const arrowRight =
    "https://img.icons8.com/?size=18&id=86520&format=png&color=ffffff";

  const slides = [
    {
      component: <Exposed active={activeSlide + 1} />,
      infobox: {
        slide: 1,
        active: activeSlide + 1,
        title: "Unsecured Without MFA",
        description:
          "Without Multifactor Authentication (MFA), your system is vulnerable to brute-force, phishing, and man-in-the-middle attacks, allowing unauthorized access.",
      },
    },
    {
      component: <Secure active={activeSlide + 1} />,
      infobox: {
        slide: 2,
        active: activeSlide + 1,
        title: "Secured With MFA",
        description:
          "With Multifactor Authentication (MFA), your system is protected against unauthorized access, ensuring that only the right people have access to your system.",
      },
    },
    {
      component: <Solution active={activeSlide + 1} />,
      infobox: {
        slide: 3,
        active: activeSlide + 1,
        title: "The Solution",
        description:
          "Secure your system with Multifactor Authentication (MFA) to protect your data and users.",
      },
    },
  ];

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", checkIsMobile);
    checkIsMobile();
    return () => window.removeEventListener("resize", checkIsMobile);
  }, []);

  useEffect(() => {
    if (!isPaused && !isMobile) {
      const interval = setInterval(() => {
        handleNext();
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [activeSlide, isPaused, isMobile]);

  const handleNext = () => {
    setActiveSlide((prev) => (prev + 1) % slides.length);
  };

  const handlePrev = () => {
    setActiveSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  const handleMouseEnter = () => {
    setIsPaused(true);
  };

  const handleMouseLeave = () => {
    setIsPaused(false);
  };

  const smoothTransition = {
    duration: 0.7,
    ease: [0.42, 0.0, 0.58, 1.0],
  };

  return (
    <div
      style={{
        height: "825px",
      }}
    >
      <h2 className="text-center mb-5">
        Built for <span className="text-tertiary">Developers</span>.<br />
        Powered by <span className="text-tertiary">Security</span>.
      </h2>

      <div className="row d-flex align-items-stretch position-relative">
        {isMobile && (
          <>
            <button
              className="btn btn-outline-tertiary position-absolute rounded-3"
              style={{
                zIndex: 10,
                top: "50%",
                width: "fit-content",
                padding: "0.5rem",
                left: 10,
              }}
              onClick={handlePrev}
            >
              <img src={arrowLeft} alt="arrow-left" />
            </button>

            <button
              className="btn btn-outline-tertiary position-absolute rounded-3"
              onClick={handleNext}
              style={{
                zIndex: 10,
                top: "50%",
                width: "fit-content",
                padding: "0.5rem",
                right: 10,
              }}
            >
              <img src={arrowRight} alt="arrow-right" />
            </button>
          </>
        )}
        {slides.map((slide, index) => (
          <motion.div
            key={index}
            className={`col-md-6 col-sm-12 ${
              activeSlide === index ? "active-slide" : "inactive-slide"
            }`}
            animate={{
              width: isMobile ? "100%" : activeSlide === index ? "50%" : "25%",
              opacity: isMobile ? (activeSlide === index ? 1 : 0) : 1,
            }}
            transition={smoothTransition}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{ overflow: "hidden" }}
          >
            <div
              className="bg-secondary position-relative rounded-4 p-2"
              style={{ height: "500px" }}
            >
              {slide.component}
            </div>
          </motion.div>
        ))}
      </div>

      <div className="row d-flex align-items-stretch">
        {slides.map((slide, index) => (
          <motion.div
            key={index}
            className={`col-md-6 col-sm-12 ${
              activeSlide === index ? "active-slide" : "inactive-slide"
            }`}
            animate={{
              width: isMobile ? "100%" : activeSlide === index ? "50%" : "25%",
              opacity: isMobile ? (activeSlide === index ? 1 : 0) : 1,
            }}
            transition={smoothTransition}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{ overflow: "hidden" }}
          >
            <Infobox info={slide.infobox} setActiveSlide={setActiveSlide} />
          </motion.div>
        ))}
      </div>
    </div>
  );
}
