import React from "react";
import { useFetcher } from "@remix-run/react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import styles from "./introduction.module.scss";

import Visual from "../../../assets/introduction/checklist.webp";
import ManWithComputer from "../../../assets/introduction/manwithcomputer.png";

export default function Video() {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const fetcher = useFetcher();

  const handleBetaTestSignup = async (event) => {
    event.preventDefault();

    try {
      if (!window.grecaptcha) {
        alert("reCAPTCHA is not ready. Please refresh the page.");
        return;
      }

      const token = await executeRecaptcha("newsletter_opt_in");

      if (!token) {
        throw new Error("Failed to generate reCAPTCHA token.");
      }

      const formData = new FormData(event.target);
      formData.append("email", formData.get("email"));
      formData.append("firstName", formData.get("firstName"));
      formData.append("companyName", formData.get("companyName"));
      formData.append("recaptchaToken", token);

      fetcher.submit(formData, {
        method: "post",
        action: "/subscribe-beta-test",
      });
    } catch (error) {
      console.error("Error generating reCAPTCHA token:", error);
      alert("Error validating reCAPTCHA. Please try again.");
    }
  };

  return (
    <div className="position-relative">
      <div className="d-flex justify-content-center flex-column align-items-center">
        <img
          src={Visual}
          alt="Visual"
          className={styles.visual}
          style={{
            width: "100%",
            height: "auto",
            maxWidth: 100,
            objectFit: "contain",
            position: "absolute",
            left: "70%",
            top: "10%",
          }}
        />
        <h2 className="mt-2">
          <span className="text-tertiary">Sign up </span> for Beta test
        </h2>
        <p className="mt-1 text-center">
          We are currently in beta testing. Sign up for the Beta test and <br />
          help us improve the product by providing feedback.
        </p>
      </div>

      {fetcher.state === "idle" && fetcher.data?.success && (
        <div className="alert alert-success w-50 mx-auto mt-4 z-1" role="alert">
          Thank you for signing up for the beta test! 🎉 Please confirm your subscription via email.
        </div>
      )}
      {fetcher.state === "idle" && fetcher.data?.error && (
        <div className="alert alert-danger w-50 mx-auto mt-4" role="alert">
          {fetcher.data.error}
        </div>
      )}
      <fetcher.Form
        id="beta-test"
        method="post"
        action="/subscribe-beta-test"
        onSubmit={handleBetaTestSignup}
      >
        <div className={`w-25 mx-auto mt-2 mb-10 ${styles.betaTestForm}`}>
          <div className="mb-2">
            <input
              className="form-control Input bg-darkblue py-1 text-white"
              type="text"
              name="firstName"
              placeholder="Enter your firstname"
              required
            />
          </div>

          <div className="mb-2">
            <input
              className="form-control Input bg-darkblue py-1 text-white"
              type="text"
              name="companyName"
              placeholder="Company name"
              required
            />
          </div>

          <div className="mb-2">
            <input
              className="form-control Input bg-darkblue py-1 text-white"
              type="email"
              name="email"
              placeholder="Company email"
              required
            />
          </div>

          <button
            type="submit"
            className="btn btn-tertiary w-100"
            disabled={fetcher.state === "submitting"}
          >
            {fetcher.state === "submitting" ? "Submitting..." : "Submit"}
          </button>
        </div>
      </fetcher.Form>
      <img
        src={ManWithComputer}
        alt="ManWithComputer"
        className={styles.manWithComputer}
        style={{
          width: "100%",
          height: "auto",
          maxWidth: 200,
          objectFit: "contain",
          position: "absolute",
          left: "15%",
          top: "0px",
        }}
      />
      <div className="ratio ratio-16x9 mt-4" id="getting-started">
        <iframe
          className="rounded-4"
          src="https://www.youtube.com/embed/VXEHOdOW1QU?si=o0TJVI-n9oYN4XZC"
          title="Introducing Headlockr - Multifactor authentication for Strapi"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
}
