import React from "react";
import Todo from "../../../../assets/features/todo.png";

export default function Totp() {
  return (
    <div className="bg-secondary p-2 rounded-4">
      <h4>Effortless, no-code setup</h4>
      <p>
        Quickly integrate MFA into your Strapi CMS without writing a single line
        of code. Privacy first design,{" "}
        <span className="text-tertiary fw-bold">no cloud</span> involved.
      </p>
      <img
        style={{
          width: "90%",
        }}
        className="mx-auto d-block mt-2"
        src={Todo}
        alt="Todo"
      />
    </div>
  );
}
