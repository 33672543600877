import React from "react";
import Cta from "./Cta";
import Images from "./Images";
import BackgroundHero from "../../../assets/hero/backgroundvisual.png";
import styles from "./hero.module.scss";

export default function Hero() {
  return (
    <div className={`row ${styles.hero}`}>
      <div className="col-lg-6 col-md-9 col-sm-12">
        <Cta />
      </div>
      <img
        style={{
          position: "absolute",
          top: "0",
          right: "0",
          width: "50%",
        }}
        src={BackgroundHero}
        alt="Background"
        className={styles.bgHero}
      />
      <div className="col-lg-6 col-md-3 col-sm-12 position-relative d-flex align-items-center justify-content-center gap-2 gap-md-0 pt-8">
        <Images />
      </div>
    </div>
  );
}
